import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { chartExample2 } from "variables/charts.js";
import { LineChartView } from "backedComponents/LineChart/LineChartView";
import { SimpleTableView } from "backedComponents/SimpleTable/SimpleTableView";
import { Enviroments } from "../enviroments/enviroments";
import HistoryOdds from "../components/HistoryOdds/HistoryOdds";
import HistoryHours from "../components/HistoryHours/HistoryHours";
import HistoryOddsPinks from "../components/HistoryOddsPinks/HistoryOddsPinks";
import SignalBot from "../components/SignalBot/SignalBot";
import SignalBotTwo from "../components/SignalBotTwo/SignalBotTwo";
import moment from "moment";
import NotificationAlert from "react-notification-alert";
import SliderBanner from "../components/SliderBanner/SliderBanner";
import InvestmentTable from "../components/InvestmentTable/InvestmentTable";
import LogRocket from "logrocket";

const Dashboard = () => {
  const [betHouse, setBetHouse] = useState("LUCKBET");
  const [analysisVela, setAnalysisVela] = useState(2);
  const [
    analysisHistoryResultsAviator,
    setAnalysisHistoryResultsAviator
  ] = useState([{ odd: 1 }]);
  const [
    analysisHistoryMostPositionsAndQuantity,
    setAnalysisHistoryMostPositionsAndQuantity
  ] = useState([0]);
  const [analysisHistoryByOdd, setAnalysisHistoryByOdd] = useState([0]);
  const [
    numberLastVelasFilterAnalysis,
    setNumberLastVelasFilterAnalysis
  ] = useState(10);
  const [numberVelas, setNumberVelas] = React.useState(10);
  const [analysisLoading, setAnalysisLoading] = useState(false);
  const [mediaState, setMedia] = useState({ average: 0, averageBefore: 0 });
  const [medianState, setMedian] = useState({ median: 0, medianBefore: 0 });
  const [chartRef2, setChartRef2] = useState(null);
  const notificationAlertRef = useRef(null);
  const notifyWarningRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(true);

  const toggleModal = () => setModalOpen(!modalOpen);

  const notify2 = place => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>
              Estamos realizando algumas melhorias no Dashboard. Quaisquer
              problemas contatar o suporte. Liberada a versão do Dashboard com a
              Mines Bet
            </b>
          </div>
        </div>
      ),
      type: "warning",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 100
    };
    notifyWarningRef.current.notificationAlert(options);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")) || undefined;
    LogRocket.identify(localStorage.getItem("id") || "nao encontrado", {
      useId: localStorage.getItem("id") || "nao encontrado",
      email: user?.email
    });
  }, []);

  const getHistoryQuantityApi = async () => {
    const dateNow = moment(new Date()).format("YYYY-MM-DD");

    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("apiToken")}`
        }
      };
      let betHouseCustom;

      if (betHouse === "BR4BET") {
        betHouseCustom = "MINES_BET";
      } else if (betHouse === "BR4BET2") {
        betHouseCustom = "BRAZUKA";
      } else {
        betHouseCustom = betHouse;
      }

      const response = await axios.get(
        `${Enviroments.API_URL_NODE}analysis-position-odd?date=${dateNow}&betHouse=${betHouseCustom}&odd=${numberVelas}`,
        headers
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
    }
  };

  const getHistoryAnalysisApi = async () => {
    const dateNow = moment(new Date()).format("YYYY-MM-DD");

    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("apiToken")}`
        }
      };

      let betHouseCustom;

      if (betHouse === "BR4BET") {
        betHouseCustom = "MINES_BET";
      } else if (betHouse === "BR4BET2") {
        betHouseCustom = "BRAZUKA";
      } else {
        betHouseCustom = betHouse;
      }

      const response = await axios.get(
        `${Enviroments.API_URL_NODE}analysis-odd?date=${dateNow}&betHouse=${betHouseCustom}&odd=${numberVelas}`,
        headers
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await getHistoryAnalysisApi();
      const resQuantity = await getHistoryQuantityApi();
      setAnalysisHistoryByOdd(res);
      setAnalysisHistoryMostPositionsAndQuantity(resQuantity);
    };

    fetchData();
  }, [numberVelas, betHouse]);

  const APOSTA_GANHA = "APOSTA_GANHA";
  const BR4BET2 = "BR4BET2";
  const PLAYPIX = "PLAYPIX";
  const BR4BET = "BR4BET";
  const LUCKBET = "LUCKBET";

  const handleIframeUrl = () => {
    switch (betHouse) {
      case APOSTA_GANHA:
        return "https://external.afiliadosapostaganha.bet/";
      case BR4BET2:
        return "https://br4bet.com/play/8197";
      case PLAYPIX:
        return "https://playpix.com/affiliates/?btag=931975";
      case BR4BET:
        return "https://go.aff.br4-partners.com/l59wgsqt";
      case LUCKBET:
        return "https://m.luck.bet.br/sportsbook/Football/Brazil?affid=46925&btag=cx-46925_433177_dashboard";
    }
  };

  const handleResultsUpdated = results => {
    setAnalysisHistoryResultsAviator(results);
  };

  return (
    <div className="content">
      <Modal isOpen={modalOpen} toggle={toggleModal} backdrop={true}>
        <ModalHeader toggle={toggleModal}>
          <h4 className="text-danger">Aproveite Agora!</h4>
        </ModalHeader>
        <ModalBody>
          <p>
            Cadastre-se na <strong>melhor casa de apostas</strong> e aproveite
            <span style={{ color: "red" }}> bônus exclusivos </span> para
            começar a operar no Aviator!
          </p>
          <p>
            Esta casa está pagando muitas <strong>velas de 1000X</strong>, não
            perca a oportunidade de multiplicar seus ganhos!
          </p>
          <Button
            color="primary"
            size="sm"
            href="https://go.luck.bet/visit/?bta=46925&brand=luckbet&afp=dashboard-aviator"
            target="_blank"
          >
            Cadastre-se Agora
          </Button>
        </ModalBody>
      </Modal>

      <div className="react-notification-alert-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Row>
        <Col xs="12" sm="8" md="4" lg="4">
          <Card>
            <CardHeader>
              <p>
                Casa selecionada:{" "}
                <h5 className="title" style={{ display: "inline" }}>
                  {betHouse}
                </h5>{" "}
              </p>
            </CardHeader>
            <CardBody className="all-icons">
              <div className="d-flex">
                <Button
                  color={betHouse === "LUCKBET" ? "primary" : "default"}
                  className="btn-round"
                  size="sm"
                  onClick={() => setBetHouse("LUCKBET")}
                >
                  LuckBet
                </Button>
                <Button
                  color={betHouse === "BR4BET" ? "primary" : "default"}
                  className="btn-round"
                  size="sm"
                  onClick={() => setBetHouse("BR4BET")}
                >
                  Br4bet
                </Button>
                <Button
                  color={betHouse === "APOSTA_GANHA" ? "primary" : "default"}
                  className="btn-round d-none"
                  size="sm"
                  onClick={() => setBetHouse("APOSTA_GANHA")}
                >
                  Aposta Ganha
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" sm="4" md="3" lg="3">
          <Card>
            <CardBody className="all-icons">
              <p>Entre no canal de novidades:</p>
              <a href="https://t.me/aviatordashboard" target="_blank">
                Novidades Dashboard
              </a>
              <p>Ganhe uma alavancagem de banca:</p>
              <a
                href="https://api.whatsapp.com/send/?phone=5511971522258&text=Alavancagem-Dashboard&type=phone_number&app_absent=0"
                target="_blank"
              >
                Clique e fale comigo
              </a>
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" sm="12" md="4" lg="4">
          <Card>
            <SliderBanner />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <HistoryOdds props={{ betHouse: betHouse }} />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <HistoryOddsPinks
            props={{ betHouse: betHouse }}
            onResultsUpdated={handleResultsUpdated}
          />
        </Col>
      </Row>
      <Row>
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notifyWarningRef} />
        </div>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <h5 className="title">{betHouse}</h5>
              <p className="category">
                Pode operar diretamente pelo nosso Dashboard!
              </p>
            </CardHeader>
            <CardBody className="all-icons">
              <iframe
                height="700"
                title="Hacking Aviator"
                style={{ border: "none" }}
                width={window.screen.width}
                name="aviator"
                className="col-md-12"
                src={handleIframeUrl()}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">Cálculo de Gestão da Banca</h5>
              <h5 className="card-category">
                Clique em Calcular logo após em Detalhes para expandir
              </h5>
              <InvestmentTable />
            </CardHeader>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">
                Gráfico de Analise de Vela:{" "}
                <strong className="text-primary">10X</strong>
              </h5>
              <LineChartView
                data={
                  chartExample2(analysisHistoryResultsAviator.map(x => x.odd))
                    .data
                }
                options={
                  chartExample2(analysisHistoryResultsAviator.map(x => x.odd))
                    .options
                }
                passedRef={newRef => setChartRef2(newRef)}
                totalData={analysisHistoryResultsAviator.length}
              />
            </CardHeader>
          </Card>
        </Col>

        <Col xs="12">
          <Card className="card-chart p-4">
            <h5 className="card-category">
              Selecione o valor da odd para ver o filtro abaixo:
            </h5>
            <div class="form-floating">
              Odd
              <select
                id="floatingSelect"
                className="form-control"
                onChange={event => setNumberVelas(event.target.value)}
                value={numberVelas}
              >
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
            </div>{" "}
          </Card>
        </Col>
        <Col lg="8">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">
                Histórico Detalhado de Analise de Vela
              </h5>
              <h5>
                Vela: <strong className="text-primary">{numberVelas}X</strong>
              </h5>
              <CardTitle tag="h3">
                <SimpleTableView
                  dataHeaders={["position", "intervalTime", "odd", "minutes"]}
                  data={analysisHistoryByOdd}
                />
                <i className="tim-icons icon-send text-primary" />{" "}
              </CardTitle>
            </CardHeader>
          </Card>
        </Col>

        <Col lg="4">
          <Card className="card-chart">
            <CardHeader>
              <h5 className="card-category">
                Posicao que repetiu o mesmo valor da ODD:{" "}
                <strong className="text-primary">{numberVelas}X</strong>
              </h5>
              <CardTitle tag="h3">
                <SimpleTableView
                  dataHeaders={["posicao", "quantidade", "porcentagem"]}
                  data={analysisHistoryMostPositionsAndQuantity}
                />
              </CardTitle>
            </CardHeader>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <HistoryHours props={{ betHouse: betHouse }} />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
